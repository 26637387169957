<template lang="pug">
  .car-classes-table
    table
      thead
        tr
          th.car-class-code.sortable(@click="handleSorting('code')")
            span {{ $t("company_system.car_class_settings.car_class_code") }}
            FaIcon.icon(:icon="sortingIcon('code')")
          th.car-class-name.sortable(@click="handleSorting('name')")
            span {{ $t("company_system.car_class_settings.car_class_name") }}
            FaIcon.icon(:icon="sortingIcon('name')")
          th.car-type-category {{ $t("company_system.car_class_settings.car_type_category") }}
          th.last-updated.sortable(@click="handleSorting('updated_at')")
            span {{ $t("company_system.car_class_settings.last_updated") }}
            FaIcon.icon(:icon="sortingIcon('updated_at')")
          th.actions
      tbody
        template(v-for="item in items")
          tr(
            :key="item.id"
            @click="$emit('select-car-class', item)"
          )
            td {{ item.code }}
            td {{ item.name }}
            td {{ extractTranslatedCategory(item) }}
            td {{ item.last_updated }}
            td.remove-action(
              v-b-tooltip.hover="{ title: $t('company_system.relation_tooltips.has_plans'), placement: 'left', disabled: !item.unremovable }"
            )
              AppIconButton.remove-action(
                :disabled="!hasEditPermission || item.unremovable"
                useAppIcon
                icon="trash"
                @click.stop="$emit('remove-car-class', item)"
              )
</template>

<script>
  // misc
  import { extractTranslatedAttribute } from "@/helpers/common"

  // mixins
  import withSorting from "@/mixins/withSorting"

  export default {
    components: {
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    mixins: [withSorting],

    props: {
      items: {
        type: Array,
        default: () => new Array()
      },

      hasEditPermission: {
        type: Boolean,
        default: true
      },

      sortingData: Object
    },

    methods: {
      extractTranslatedCategory(item) {
        return extractTranslatedAttribute(item, "category")
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/matchings.sass"
  @import "@/assets/styles/mixins/common.sass"

  .car-classes-table
    +listing-container(123px)
    margin-top: 30px

    table
      +custom-table
      +matchings-table-header-sticky

      table-layout: fixed
      overflow-wrap: break-word

      thead
        th
          &.car-class-code,
          &.last-updated
            width: 16%

          &.car-class-name,
          &.car-class-category
            width: 32%

          &.actions
            width: 4%
      tbody
        tr
          cursor: pointer

          &:hover
            box-shadow: 0 0 3px 0 $default-gray

          td
            .remove-action
              +icon-button($default-purple)
</style>
